* {
    font-family: 'Poppins', sans-serif;
}

.emoji {
    display: inline-block;
    width: auto;
    height: 1em;
    vertical-align: -0.125em;
}

html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    overflow-y: auto;
}

.base {
    padding: 2rem;
    /* height: 100%;
    width: 100%; */
    background-repeat: repeat;
    background-size: cover;
}

.slider-row {
    /* margin-left: 2rem;
    margin-top: 2rem; */
    margin-bottom: 3rem;
    /* overflow: auto; */
}

h1, h2 {
    color: white;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #c4c4c4 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 3px;
}

*::-webkit-scrollbar-track {
    /* background: #ffffff; */
    width: 3px;
}

*::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 10px;
    border: 3px solid #c4c4c400;
    width: 3px;
}

.add-movie {
    position: absolute;
    background-color: #3c3c3c;
    width: 50px;
    height: 50px;
    top: 50px;
    right: 50px;
    border-radius: 17px;
    text-align: center;
    line-height: 50px;
    transition: 0.2s;
    cursor: pointer;
}

.add-movie:hover {
    transform: scale(1.2);
}

.add-movie-form {
    height: auto;
    /* background-color: #3c3c3c; */
    position: absolute;
    z-index: 999;
    right: 30px;
    max-width: 30vw;
    overflow: hidden;
}

.categoryTitle {
    color: white;
    font-weight: bold;
}

/* @import url('https://fonts.googleapis.com/css?family=Poppins:400'); */

html {
    font-size: 100%;
}

/*16px*/

body {
    background: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.75;
    color: #000000;
}

p {
    margin-bottom: 1rem;
}

h1, h2, h3, h4, h5 {
    margin: 3rem 0 .5rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.3;
}

h1 {
    margin-top: 0;
    font-size: 3.052rem;
}

h2 {
    font-size: 2.441rem;
}

h3 {
    font-size: 1.953rem;
}

h4 {
    font-size: 1.563rem;
}

h5 {
    font-size: 1.25rem;
}

small, .text_small {
    font-size: 0.8rem;
}

body {
    background-color: #111111;
    margin: 0;
    overflow-x: hidden;
}

/* Credit goes to Mathieu S. https://codepen.io/iceable/pen/yLBrZOd */

.bg {
    position: fixed;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    width: 200%;
    height: 200vh;
    background: transparent url('http://assets.iceable.com/img/noise-transparent.png') repeat 0 0;
    background-repeat: repeat;
    animation: bg-animation .2s infinite;
    opacity: .9;
    visibility: visible;
    z-index: 99999;
    pointer-events: none;
}

@keyframes bg-animation {
    0% {
        transform: translate(0, 0)
    }

    10% {
        transform: translate(-5%, -5%)
    }

    20% {
        transform: translate(-10%, 5%)
    }

    30% {
        transform: translate(5%, -10%)
    }

    40% {
        transform: translate(-5%, 15%)
    }

    50% {
        transform: translate(-10%, 5%)
    }

    60% {
        transform: translate(15%, 0)
    }

    70% {
        transform: translate(0, 10%)
    }

    80% {
        transform: translate(-15%, 0)
    }

    90% {
        transform: translate(10%, 5%)
    }

    100% {
        transform: translate(5%, 0)
    }
}