.slider-item, .slider-item-content {
    transition: .15s;
    -webkit-transition: .15s;
    -moz-transition: .15s;
    -ms-transition: .15s;
    -o-transition: .15s;
    transition-delay: 0;
    cursor: pointer;
}

.slider-item {
    background-color: black;
    border-radius: 17px;
    -webkit-border-radius: 17px;
    -moz-border-radius: 17px;
    -ms-border-radius: 17px;
    -o-border-radius: 17px;
    margin: 0.3rem;
    width: 360px;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 300px;
    min-height: 205px;
    display: inline-block;
    position: relative;
    z-index: 0;
}

.slider-item:hover {
    /*width: 360px;
    height: 300px;
    */transform: scale(1.2);
    -webkit-transform:scale(1.2);
    -moz-transform:scale(1.2);
    -ms-transform:scale(1.2);
    -o-transform:scale(1.2);
    transition-delay: .2s;
    z-index: 50;
}

.slider-item:hover > .slider-item-content {
    transition-delay: .2s;
}

.slider-item:first-of-type {
    transform-origin: left;
} 

.slider-item-content {
    opacity: 0;
    color: black;
    padding: 10px 20px;
    text-align: center;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 17px;
    -webkit-border-radius: 17px;
    -moz-border-radius: 17px;
    -ms-border-radius: 17px;
    -o-border-radius: 17px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.slider-item-content > p {
    margin: 0;
}

.slider-item-content > * {
    transform: scale(0.8333);
    -webkit-transform: scale(0.8333);
    -moz-transform: scale(0.8333);
    -ms-transform: scale(0.8333);
    -o-transform: scale(0.8333);
}

.slider-item:hover .slider-item-content {
    opacity: 1;
    background-color: rgba(0, 0, 0, .6);
}

.title {
    font-size: 24px;
    font-weight: bold;
}

.movie-year {
    font-size: 1rem;
    opacity: 0.8;
}

.legends {
    
}

.legend {
    font-size: 14px;
    color: white;
    margin: 0;
    opacity: 0.8;
}

.status-watched, .status-notWatched{
    padding: 7px;
    border-radius: 6px;
    display: inline-block;
}

.status-watched > p, .status-notWatched > p{
    margin: 0;
}

.status-watched {
    background-color: #25AC5B;
}

.status-notWatched {
    background-color: #CC2F2F;
}

.rating {
    display: flex;
}

.delete-movie {
    position: absolute;
    top: 10px;
    right: 10px;
}
