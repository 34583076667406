.results-list {
    list-style: none;
    background-color: #252525;
    padding: 30px;
    width: 100%;
    border-radius: 17px;
    -webkit-border-radius: 17px;
    -moz-border-radius: 17px;
    -ms-border-radius: 17px;
    -o-border-radius: 17px;
    transition: 1s;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
    max-height: 41vh;
    overflow: auto;
}

.results-background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: black;
}

form {
    width: 100%;
}

input {
    width: 100%;
    height: 70px;
    font-size: 24px;
}

.search-result {
    color: white;
    list-style: none;
    display: flex;
    margin: 1rem 0;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}

.search-result:hover {
    cursor: pointer;
    transform:scale(1.1);
    -webkit-transform:scale(1.1);
    -moz-transform:scale(1.1);
    -ms-transform:scale(1.1);
    -o-transform:scale(1.1);
    background-color: #2e2e2e;
}

.search-result:first-of-type{
    margin-top: 0px;
}

.search-result:last-of-type{
    margin-bottom: 0px;
}

.search-result > *{
    pointer-events: none;
}

.img{
    width: auto;
    height: auto;
    max-width: 50px;
    margin-right: 20px;
    display: flex;
}

.img > img {
    width: 100%;
    height: auto;
}

.form-error {
    color: rgb(240, 116, 116);
    transition: 1s;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
}